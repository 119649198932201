// 1人当たり週次推移
import {
  CmSalesImpactGraphData,
  CmSalesImpactReportsDetails,
  PeriodTotalPurchaseIncreaseGraph,
  PeriodTotalPurchaseIncreasePerPersonGraph,
  PurchasePerPersonAndFrequencyGraph,
  CmSalesImpactProductsDetails
} from '@/api/openapi';
import { roundNumber, resolveNumberWithUnit } from '@/common/formatter';
import { TOTAL_TYPE } from '@/composables/campaign/cmsi/product/weeklyGraph';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

export const exportfileName = ({
  label,
  mode,
  id,
  report
}: {
  label: string;
  mode: 'report' | 'product';
  id: number;
  report?: CmSalesImpactReportsDetails;
}): string => {
  if (label) {
    switch (mode) {
      case 'report':
        return `${label}_${id}_${report?.startDate}-${report?.endDate}`;
      case 'product':
        return `${label}_${id}`;
    }
  }
  return '';
};
// Csv
const createSampleConditionTexts = (
  title: string,
  details: CmSalesImpactProductsDetails | CmSalesImpactReportsDetails,
  advertisingCost: number | null, // 商品詳細の時は無し
  dataLabel: string | null, // 週次推移の時のみ有り
  averageFrequency: number | null,
  startDate?: string
): string[][] => {
  // NOTE: 商品詳細APIのstartDateが取得データ期間と異なるため対応
  const start = startDate ? startDate : details.startDate;
  const from: string = format(new Date(start), 'yyyy年MM月dd日(E)', {
    locale: ja
  });
  const to: string = format(new Date(details.endDate), 'yyyy年MM月dd日(E)', {
    locale: ja
  });

  const sampleCondition: string[][] = [];
  sampleCondition.push([title]);
  sampleCondition.push(['企業:', details.companyName]);
  sampleCondition.push(['商品:', details.productName]);
  sampleCondition.push(['エリア:', details.areaName]);
  sampleCondition.push(['期間:', `${from}〜${to}`]);
  sampleCondition.push([
    '期間内最新サンプルサイズ:',
    roundNumber(details.sampleSize)
  ]);
  sampleCondition.push([
    'リーチ基準:',
    `${details.reachWeeks}週間で${details.reachFrequency}回以上の有効接触`
  ]);
  if (advertisingCost !== null) {
    sampleCondition.push([
      '出稿費用:',
      `${
        advertisingCost > 0
          ? resolveNumberWithUnit(advertisingCost, 3, '円')
          : 'ー'
      }`
    ]);
  }
  if (averageFrequency !== null) {
    sampleCondition.push([
      '平均リーチ率:',
      roundNumber(averageFrequency, 2) + '%'
    ]);
  }
  if (dataLabel !== null) {
    sampleCondition.push(['＜選択した出稿状況＞:', dataLabel]);
  }
  sampleCondition.push([
    '作成日時:',
    format(new Date(), 'yyyy年MM月dd日(E) HH:mm', {
      locale: ja
    })
  ]);
  sampleCondition.push(['データ提供元:', 'Switch Media, Inc.']);
  return sampleCondition;
};

const outputCsv = (
  filename: string,
  sampleCondition: string[][],
  header: string[],
  body: string[][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const csvData: string[][] = [];
  csvData.push(...sampleCondition);
  csvData.push([]);
  csvData.push(header);
  csvData.push(...body);

  const link = document.createElement('a');
  const blob = new Blob(
    [
      new Uint8Array([0xef, 0xbb, 0xbf]),
      $papa.unparse({
        data: csvData
      })
    ],
    {
      type: 'text/csv'
    }
  );

  link.setAttribute('download', filename);
  link.setAttribute('href', window.webkitURL.createObjectURL(blob));
  link.click();
};

export const outputPeriodTotalPurchaseIncreaseCsv = (
  filename: string,
  title: string,
  graphData: PeriodTotalPurchaseIncreaseGraph,
  details: CmSalesImpactReportsDetails,
  averageFrequency: number | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(
    title,
    details,
    details.advertisingCost,
    null,
    averageFrequency
  );

  // headers
  const tableHeader: string[] = [
    '未接触時 購入額(円)',
    '購入増加額(円)',
    '購入額(円)'
  ];

  // body
  const tableBody = [
    [
      roundNumber(graphData.unreachedPurchaseAmount, 2),
      roundNumber(graphData.cmEffect, 2),
      roundNumber(graphData.totalPurchaseAmount, 2)
    ]
  ];

  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};

export const outputPeriodTotalPurchaseIncreasePerPersonCsv = (
  filename: string,
  title: string,
  graphData: PeriodTotalPurchaseIncreasePerPersonGraph,
  details: CmSalesImpactReportsDetails,
  averageFrequency: number | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(
    title,
    details,
    details.advertisingCost,
    null,
    averageFrequency
  );

  // headers
  const tableHeader: string[] = [
    '未接触時 購入額(円)',
    '購入増加額(円)',
    'CMリーチ者 購入額(円)'
  ];

  // body
  const tableBody = [
    [
      roundNumber(graphData.unreachedPurchaseAmount, 2),
      roundNumber(graphData.cmEffect, 2),
      roundNumber(graphData.cmReachPurchaseAmount, 2)
    ]
  ];

  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};

export const outputPurchasePerPersonAndFrequencyCsv = (
  filename: string,
  title: string,
  graphData: Array<PurchasePerPersonAndFrequencyGraph>,
  details: CmSalesImpactReportsDetails,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(
    title,
    details,
    details.advertisingCost,
    null,
    null
  );

  // headers
  const tableHeader: string[] = ['フリークエンシー', '平均購入額(円)'];

  // body
  const tableBody = graphData.map((v, i) => [
    v.frequency + (i === 9 ? '回以上' : '回'),
    roundNumber(v.purchaseAmountPerPerson, 2)
  ]);
  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};

// 商品詳細からの週次購入額の推移（合計）
export const outputWeeklyPurchaseIncreaseCsv = (
  filename: string,
  title: string,
  graphData: CmSalesImpactGraphData,
  details: CmSalesImpactProductsDetails | CmSalesImpactReportsDetails,
  advertisingCost: number | null, // レポート詳細の時のみ有り  dataType: string
  dataType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const dataLabel = TOTAL_TYPE[dataType].label;
  let dataUnit = TOTAL_TYPE[dataType].unit;
  // NOTE: 画面とCSVでUniのアリナシが異なるため、ここで追加
  if (dataLabel == 'リーチ人数') dataUnit = '人';

  const sampleConditionTexts = createSampleConditionTexts(
    title,
    details,
    advertisingCost,
    dataLabel,
    null,
    graphData.weeklyPurchaseIncreaseGraph[0].weeklyDate // NOTE: 商品詳細APIのstartDateが取得データ期間と異なるため対応
  );

  // headers
  const tableHeader: string[] = [
    '週',
    '購入総額(円)',
    '未接触時 購入額(円)',
    '購入増加額(円)',
    `${dataLabel}${dataUnit ? '(' + dataUnit + ')' : ''}`
  ];

  // body
  const tableBody = graphData.weeklyPurchaseIncreaseGraph.map(v => [
    v.weeklyDate,
    roundNumber(v.totalPurchaseAmount, 1),
    roundNumber(v.totalPurchaseAmountWithoutCm, 2),
    roundNumber(v.cmEffect, 2),
    roundNumber(
      (() => {
        switch (dataType) {
          case TOTAL_TYPE.reachRate.id:
            return v.reachRate;
          case TOTAL_TYPE.reachNumberOfPeople.id:
            return v.reachNumberOfPeople;
          default:
            return v.grp;
        }
      })(),
      (() => {
        switch (dataType) {
          case TOTAL_TYPE.reachNumberOfPeople.id:
            return 1;
          default:
            return 2;
        }
      })()
    )
  ]);
  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};

// 1人当たり週次推移
export const outputWeeklyPurchaseIncreasePerPersonCsv = (
  filename: string,
  title: string,
  graphData: CmSalesImpactGraphData,
  details: CmSalesImpactProductsDetails | CmSalesImpactReportsDetails,
  advertisingCost: number | null, // レポート詳細の時のみ有り  dataType: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(
    title,
    details,
    advertisingCost,
    null,
    null,
    graphData.weeklyPurchaseIncreasePerPersonGraph[0].weeklyDate // NOTE: 商品詳細APIのstartDateが取得データ期間と異なるため対応
  );

  // headers
  const tableHeader: string[] = [
    '週',
    '購入増加額(円)',
    'CMリーチ者(円)',
    'CMリーチ者 未接触時(円)',
    'CM未接触者(円)'
  ];

  // body
  const tableBody = graphData.weeklyPurchaseIncreasePerPersonGraph.map(v => [
    v.weeklyDate,
    roundNumber(v.cmEffect, 2),
    roundNumber(v.cmReachPurchaseAmount, 2),
    roundNumber(v.cmReachPurchaseAmountWithoutCm, 2),
    roundNumber(v.cmUnreachedPurchaseAmount, 2)
  ]);
  outputCsv(filename, sampleConditionTexts, tableHeader, tableBody, $papa);
};
