<template>
  <Box class="brandlif-loading-message" text-align="center">
    <ThreeDotsSvg
      v-if="type === 'summary'"
      class="loading"
      :style="{ width: 40, height: 10 }"
    />
    <BarsSvg v-if="type === 'graph'" />
    <Typography size="s" color="primary" line-height="1.5" class="mt-6">
      分析結果を集計中です
    </Typography>
    <Typography size="s" color="primary" line-height="1.5">
      読み込み完了まで、最大3分程度かかる場合があります
    </Typography>
  </Box>
</template>
<script>
import { defineComponent } from 'vue';
import Box from '@/components/layout/Box.vue';
import BarsSvg from '@/assets/images/bars.svg?inline';
import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'LoadingMessage',
  components: {
    BarsSvg,
    Box,
    ThreeDotsSvg,
    Typography
  },
  props: {
    type: {
      type: String,
      default: 'graph'
    }
  }
});
</script>
<style lang="scss">
.brandlif-loading-message {
  .ui-typography.primary,
  .loading {
    color: var(--chart-blue-2);
  }
}
</style>
