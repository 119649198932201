import { ref, Ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';

import { CmsiApi } from '@/api';
import {
  CmSalesImpactProductsDetails,
  CmSalesImpactGraphData
} from '@/api/openapi';
import { _fetchGraphs } from '@/composables/campaign/cmsi/report';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { httpCode } from '@/common/constant';

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' },
    {
      name: CAMPAIGN_ROUTES.cmsi,
      label: '売上効果分析',
      query: {
        mode: 'static'
      }
    }
  ],
  current: { label: '' }
};

interface CmsiProductReturnType {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  isLoading: Ref<boolean>;
  product: Ref<CmSalesImpactProductsDetails>;
  isLoadingGraph: Ref<boolean>;
  graphs: Ref<CmSalesImpactGraphData>;
  reload: (number) => void;
  // eslint-disable-next-line
  papa: any;
}

export const useCmsiProduct = (): CmsiProductReturnType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const papa: any = inject('$papa');

  const route = useRoute();
  const router = useRouter();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const productId = ref(Number(params.productId));
  const product = ref({} as CmSalesImpactProductsDetails);
  const graphs = ref({} as CmSalesImpactGraphData);
  const breadcrumbs = ref(BREADCRUMBS);

  // 商品の取得
  const get = async (id: number): Promise<boolean> => {
    product.value = await fetch({ companyId: companyId.value, productId: id });

    // 商品情報の取得に失敗した場合、後続の処理は実施しない
    if (Object.keys(product.value).length === 0) {
      await router.push({
        name: CAMPAIGN_ROUTES.cmsi,
        query: {
          mode: 'static'
        }
      });
      return false;
    }

    // データ集計中の場合、ページ遷移する
    if (!product.value.isAvailableWeeklyReport) {
      await router.push({
        name: CAMPAIGN_ROUTES.cmsi,
        query: {
          mode: 'static'
        }
      });
      toast({
        title: 'データ集計中',
        message: '集計中のためアクセスできません。一覧へ遷移しました。',
        variant: 'error'
      });
      return false;
    }
    // レポートグラフを取得
    graphs.value = await fetchGraphs({
      companyId: companyId.value,
      productId: id
    });

    return true;
  };
  const [isLoading, fetch] = useLoading(_fetch);
  const [isLoadingGraph, fetchGraphs] = useLoading(_fetchGraphs);
  (async () => {
    const success = await get(productId.value);

    if (!success) return;

    // 最終アクセスの更新
    await putLastAccess(productId.value);

    // タイトルの書き換え
    if (
      product.value.productName &&
      // 現在表示中の画面がCMSI商品画面の場合のみ、タイトル書き換えを実行する
      route.name === CAMPAIGN_ROUTES.cmsiProduct
    ) {
      const name = product.value.productName;
      const { title } = document;
      document.title = name + ' ' + title;
      // パンくずの書き換え
      breadcrumbs.value.current.label = name;
    }
  })();

  // 設定保存後のリロード
  const reload = async (id: number) => {
    await get(id);
  };

  return {
    breadcrumbs,
    isLoading,
    product,
    isLoadingGraph,
    graphs,
    reload,
    papa
  };
};

export const _fetch = async ({
  companyId,
  productId
}: {
  companyId: number;
  productId: number;
}): Promise<CmSalesImpactProductsDetails> => {
  // 商品の取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactProductsProductIdDetails(
      productId,
      companyId
    );
    return data;
  } catch (e) {
    // 商品が削除されている場合
    if (e.response && e.response.status === httpCode.notFound) {
      toast({
        title: '商品情報の取得に失敗',
        message: '商品が削除されています。一覧へ遷移しました。',
        variant: 'error'
      });
      return {} as CmSalesImpactProductsDetails;
    }
    // その他エラーの場合
    toast({
      title: '商品情報の取得に失敗',
      variant: 'error'
    });
    return {} as CmSalesImpactProductsDetails;
  }
};

const putLastAccess = async (productId): Promise<void> => {
  // 商品の取得
  try {
    await CmsiApi.putCmSalesImpactLastAccessProductProductId(productId);
  } catch (err) {
    toast({
      title: '最終アクセス更新失敗',
      variant: 'error'
    });
  }
};
