<template>
  <div class="tabs">
    <button
      v-for="(tab, i) in tabList"
      v-ripple
      :ref="
        el => {
          if (el) tabRefs[i] = el;
        }
      "
      class="tab"
      :class="{ active: tab.id === activeId, lock: tab.lock }"
      :id="tab.id"
      :key="tab.id"
      :disabled="tab.lock"
      @click="changeTab(tab.id, tab.lock)"
    >
      <Flex align-center justify-center>
        <Typography
          :color="tab.lock ? 'disabled' : 'inherit'"
          class="default-label"
        >
          {{ tab.name }}
        </Typography>
        <Typography
          v-if="tab.count >= 0"
          size="xs"
          :color="tab.lock ? 'disabled' : 'primary'"
          class="ml-1"
        >
          ({{ tab.count }})
        </Typography>
        <Icon
          v-if="tab.lock && tab.showLockIcon"
          name="lock"
          class="ml-1"
          sides="12"
          color="disabled"
        />
      </Flex>
      <Typography bold class="active-label">
        {{ tab.name }}
      </Typography>
    </button>
    <span class="slider" :style="sliderStyles" />
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, watch } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Icon from '@/components/ui/Icon.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Tabs',
  components: {
    Flex,
    Icon,
    Typography
  },
  props: {
    tabList: {
      /**
       * {
       *   id: string;
       *   name: string;
       *   count?: number;
       *   active?: boolean;
       *   lock?: boolean;
       *   showLockIcon?: boolean;
       * }[]
       */
      type: Array,
      required: true
    }
  },
  emits: ['change'],
  setup: (props, { emit }) => {
    const activeId = ref(
      props.tabList.find(tab => tab.active)?.id || props.tabList[0].id
    );
    const sliderStyles = ref({
      width: 0,
      left: 0
    });
    const tabRefs = ref([]);

    const slide = () => {
      const activeTab = tabRefs.value.find(tab => tab.id === activeId.value);

      const activeIndex = tabRefs.value.indexOf(activeTab);
      const sliderLeft = tabRefs.value.reduce((total, current, index, tabs) => {
        if (index === 0 || index > activeIndex) return total;
        return total + tabs[index - 1].scrollWidth + 4;
      }, 0);
      sliderStyles.value.width = `${activeTab.scrollWidth}px`;
      sliderStyles.value.left = `${sliderLeft}px`;
    };

    const changeTab = (id, lock) => {
      if (lock) return;
      activeId.value = id;
      slide();
      emit('change', id);
    };

    onMounted(() => {
      slide();
    });

    watch(
      () => props.tabList,
      () => {
        setTimeout(() => {
          slide();
        });
      },
      { deep: true }
    );

    return {
      tabRefs,
      activeId,
      sliderStyles,
      changeTab
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/effect';
@import '@/styles/spacing';

.tabs {
  display: flex;
  align-items: flex-end;
  position: relative;
  border-bottom: 1px solid var(--divider-color);
  color: var(--text-color);
}
.tab {
  position: relative;
  padding: $spacing-base * 3 $spacing-base * 4;
  text-align: center;
  cursor: default;

  &:not(:last-child) {
    margin-right: $spacing-base;
  }

  &:not(.lock):not(.active) {
    @extend .hover-background;
  }

  &:hover {
    border-radius: 4px;
  }

  .active-label {
    position: absolute;
    top: 12px;
    left: 16px;
    opacity: 0;
  }

  &.active {
    cursor: default;
    &:hover {
      opacity: 1;
    }

    .default-label {
      opacity: 0;
    }

    .active-label {
      opacity: 1;
    }
  }
}

.spacer {
  flex: 1;
}

.slider {
  position: absolute;
  bottom: -1px;
  left: 0;
  border-bottom: 2px solid var(--primary-color);
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  z-index: 1;
}
</style>
