import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { CHART_OPTIONS_BASE } from '@/composables/campaign/cmsi';
import { CmSalesImpactGraphData, CostEffectKpiPanel } from '@/api/openapi';
import { roundNumberWithUnit, round } from '@/common/formatter';
import useLoading from '@/composables/loading';

import { _fetchGraphs } from '@/composables/campaign/cmsi/report';

export const KPIS: { label: string; id: string; unit: string }[] = [
  { label: '購入増加額', id: 'cmEffect', unit: '円' },
  { label: '出稿費用', id: 'advertisingCost', unit: '円' },
  { label: 'ROAS', id: 'roas', unit: '%' },
  {
    label: '1人当たり購入増加額',
    id: 'personCmEffect',
    unit: '円'
  },
  {
    label: '平均週次リーチ人数',
    id: 'averageWeeklyReachNumberOfPeople',
    unit: '人'
  },
  {
    label: '週数',
    id: 'numberOfWeeks',
    unit: '週'
  }
];

const GRAPHS = [
  'periodTotalPurchaseIncreaseGraph',
  'periodTotalPurchaseIncreasePerPersonGraph'
];

interface PeriodGraphType {
  unreachedPurchaseAmount: {
    label: string;
    id: string;
    color: string;
  };
  cmEffect: {
    label: string;
    id: string;
    color: string;
  };
  variable: {
    label: string;
    id: string;
    color: string;
  };
}

export const PERIOD_TOTAL_TYPE = {
  unreachedPurchaseAmount: {
    label: '未接触時 購入額',
    id: 'unreachedPurchaseAmount',
    color: '#A0A8A8'
  },
  cmEffect: {
    label: '購入増加額',
    id: 'cmEffect',
    color: '#99D77B'
  },
  variable: {
    label: '購入総額',
    id: 'totalPurchaseAmount',
    color: '#6B90DF'
  }
};

export const PERIOD_PERSON_TYPE = {
  unreachedPurchaseAmount: {
    label: '未接触時 購入額',
    id: 'unreachedPurchaseAmount',
    color: '#A0A8A8'
  },
  cmEffect: {
    label: '購入増加額',
    id: 'cmEffect',
    color: '#99D77B'
  },
  variable: {
    label: 'CMリーチ者 購入額',
    id: 'cmReachPurchaseAmount',
    color: '#6B90DF'
  }
};

export const CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart,
    height: 320,
    type: 'column'
  },
  plotOptions: {
    ...CHART_OPTIONS_BASE.plotOptions,
    series: {
      pointWidth: 40
    },
    column: {
      zIndex: 1,
      stacking: 'normal',
      dataLabels: {
        enabled: true,
        crop: false,
        overflow: 'none',
        color: '#000000',
        verticalAlign: 'top',
        useHTML: true,
        y: -40,
        formatter: function(): string {
          const chart = (this as unknown) as {
            point: { y: number };
            percentage: number;
          };
          if (chart.point.y === 0) return '';
          return `<div style="text-align:center">${roundNumberWithUnit(
            chart.point.y,
            2,
            '円'
          )}<br>${round(chart.percentage, 2)}%</div>`;
        }
      }
    },
    line: {
      marker: {
        enabled: false
      },
      color: 'grey',
      enableMouseTracking: false,
      lineWidth: 1,
      zIndex: 0,
      dashStyle: 'ShortDash'
    }
  },
  tooltip: {
    enabled: false
  },
  legend: false
};

export interface Graph {
  [key: string]:
    | string
    | Array<number | null>
    | boolean
    | { [key: string]: string | boolean };
  data: Array<number | null>;
}

interface PurchaseAmount {
  isLoading: Ref<boolean>;
  data: Ref<Graph[][]>;
  averageReachRate: Ref<number>;
  dataKpi: Ref<CostEffectKpiPanel>;
}

export const usePurchaseAmount = ({
  reportId
}: {
  reportId: number;
}): PurchaseAmount => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);
  const productId = Number(params.productId);
  const graphDataList = ref<Graph[][]>([]);
  const averageReachRate = ref(0);
  const dataKpi = ref({} as CostEffectKpiPanel);

  // 最新のレポート詳細を取得
  const [isLoading, fetch] = useLoading(_fetchGraphs);
  (async () => {
    const raw = await fetch({ reportId, companyId, productId });
    graphDataList.value = await formatGraphData(raw);
    averageReachRate.value =
      raw['periodTotalPurchaseIncreaseGraph']['averageReachRate'];
    dataKpi.value = raw['costEffectKpiPanel'];
  })();

  return {
    isLoading,
    data: graphDataList,
    averageReachRate,
    dataKpi
  };
};
const aryMin = (a, b) => {
  return Math.min(a, b);
};
const aryMax = (a, b) => {
  return Math.max(a, b);
};

const createGraphData = (
  raw: CmSalesImpactGraphData,
  v: string,
  periodGraphType: PeriodGraphType
): Graph[] => {
  const empty = Array(Object.values(periodGraphType).length).fill(null);
  const data: Graph[] = Object.values(periodGraphType).map((e, i) => {
    const tmp = [...empty];
    // マイナスの時は0にする
    tmp[i] = raw[v][e.id] > 0 ? raw[v][e.id] : 0;
    return {
      type: 'column',
      name: e.label,
      color: e.color,
      data: tmp
    };
  });
  const diff = [
    periodGraphType.unreachedPurchaseAmount,
    v === 'periodTotalPurchaseIncreaseGraph'
      ? PERIOD_TOTAL_TYPE.variable
      : PERIOD_PERSON_TYPE.variable
  ];
  const max = diff.map(e => raw[v][e.id]).reduce(aryMax);
  const min = diff.map(e => raw[v][e.id]).reduce(aryMin);
  // ％をchartに判定させる
  data.unshift({
    type: 'column',
    name: '',
    color: 'transparent',
    dataLabels: {
      enabled: false
    },
    enableMouseTracking: false,
    data: [max - (data[0].data[0] || 0), null, max - (data[2].data[2] || 0)]
  });

  // マイナスの時は表示しない
  if (raw[GRAPHS[0]].cmEffect > 0) {
    // 真ん中を押し上げる
    data.push({
      type: 'column',
      name: '',
      color: 'transparent',
      dataLabels: {
        enabled: false
      },
      enableMouseTracking: false,
      data: [null, min, null]
    });
    // line1
    data.push({
      name: 'left-middle',
      type: 'line',
      data: [data[1].data[0], data[1].data[0], null]
    });
    // line2
    data.push({
      name: 'middle-right',
      type: 'line',
      data: [null, data[3].data[2], data[3].data[2]]
    });
  }
  return data;
};

export const formatGraphData = (raw: CmSalesImpactGraphData): Graph[][] => {
  if (!raw[GRAPHS[0]]) return [] as Graph[][];
  return GRAPHS.map(v => {
    const data = createGraphData(
      raw,
      v,
      v === 'periodTotalPurchaseIncreaseGraph'
        ? PERIOD_TOTAL_TYPE
        : PERIOD_PERSON_TYPE
    );
    return data;
  });
};
