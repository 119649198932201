import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { CmsiApi } from '@/api';
import { CmSalesImpactReports } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

interface Weaken extends CmSalesImpactReports {
  // eslint-disable-next-line
  startDate: any;
  // eslint-disable-next-line
  endDate: any;
  // eslint-disable-next-line
  lastUpdateDate: any;
}

export interface ReportList extends Weaken {
  startDate: Date;
  endDate: Date;
  lastUpdateDate: Date;
}

interface CmsiReportListReturnType {
  isLoading: Ref<boolean>;
  reports: Ref<ReportList[]>;
  get: () => void;
}

export const useCmsiReportList = (): CmsiReportListReturnType => {
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const productId = ref(Number(params.productId));
  const reports = ref([] as ReportList[]);

  // レポート一覧の取得
  const [isLoading, fetch] = useLoading(_fetch);
  const get = async () => {
    reports.value = [] as ReportList[];
    // APIからstringでくるため、filter用にDateに変換
    const raw = await fetch({ companyId, productId });
    reports.value = raw.map(v => ({
      ...v,
      startDate: new Date(v.startDate),
      endDate: new Date(v.endDate),
      lastUpdateDate: new Date(v.lastUpdateDate)
    }));
  };
  (async () => {
    await get();
  })();

  return {
    isLoading,
    reports,
    get
  };
};

const _fetch = async ({
  companyId,
  productId
}): Promise<CmSalesImpactReports[]> => {
  // レポート一覧の取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactReports(
      productId.value,
      companyId.value
    );
    return data;
  } catch (e) {
    toast({
      title: 'レポート一覧の取得失敗',
      variant: 'error'
    });
    return [] as CmSalesImpactReports[];
  }
};
