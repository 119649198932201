import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ref, Ref } from 'vue';

interface Filter {
  label: string;
  value: string;
}
interface Header {
  filter?: boolean;
  sortable?: boolean;
  id: string;
  label?: string;
  class: string;
  matchMode?: string;
  filterOptions?: Filter[];
  filterField?: string;
}

interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}
interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
}

const STR_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];
const NUM_FILTER: Filter[] = [
  { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
  { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
  { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
  { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
];
const DATE_FILTER: Filter[] = [
  { label: '等しい', value: FilterMatchMode.DATE_IS },
  { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
  { label: '以前', value: FilterMatchMode.DATE_BEFORE },
  { label: 'より後', value: FilterMatchMode.DATE_AFTER }
];
export const HEADERS: Header[] = [
  {
    filter: true,
    sortable: true,
    filterField: 'string',
    id: 'title',
    label: 'タイトル',
    class: 'auto',
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'startDate',
    label: '開始日',
    class: 'p-sortable-column--md center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'date',
    id: 'endDate',
    label: '終了日',
    class: 'p-sortable-column--md center',
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'purchaseIncreaseAmount',
    label: '購入増加額',
    class: 'p-sortable-column--md end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    filter: true,
    sortable: true,
    filterField: 'number',
    id: 'grp',
    label: 'GRP',
    class: 'p-sortable-column--sm end',
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER
  },
  {
    id: 'lastUpdateDate',
    label: '最終更新日',
    class: 'p-sortable-column--smd center',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER,
    filterField: 'date'
  },
  {
    id: 'actions',
    class: 'p-sortable-column--sm'
  }
];

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const tmp = HEADERS.map(v => {
    if (!v.filter) return;
    return {
      id: v.id,
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: v.matchMode }]
    };
  }).filter(Boolean) as DataTabelFilter[];
  filters.value = tmp.reduce((p, c) => {
    return { ...p, [c.id]: c };
  }, {});

  return {
    filters
  };
};
