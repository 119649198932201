import { ref, Ref } from 'vue';
import { CmSalesImpactGraphData } from '@/api/openapi';
import { CHART_OPTIONS_BASE } from '@/composables/campaign/cmsi';

import { useTabs } from '@/composables/tabs';

export const TOTAL_TYPE = {
  reachRate: { label: 'リーチ率', id: 'reachRate', unit: '%' },
  reachNumberOfPeople: {
    label: 'リーチ人数',
    id: 'reachNumberOfPeople',
    unit: ''
  },
  grp: { label: 'GRP', id: 'grp', unit: '' },
  totalPurchaseAmountWithoutCm: {
    label: '未接触時 購入額',
    id: 'totalPurchaseAmountWithoutCm'
  },
  totalPurchaseAmount: { label: '購入総額', id: 'totalPurchaseAmount' },
  increase: { label: '購入増加額', id: 'increase' },
  decrease: { label: '減少', id: 'decrease' },
  transparent: { label: '', id: 'transparent' }
};
export const TOTAL_CHART_PRESET = [
  {
    id: 'active',
    color: '#B5C7EF',
    type: 'column',
    legendIndex: 1
  },
  {
    id: TOTAL_TYPE.totalPurchaseAmountWithoutCm.id,
    color: '#6B90DF',
    type: 'line'
  },
  {
    id: TOTAL_TYPE.totalPurchaseAmount.id,
    color: '#A0A8A8',
    type: 'line'
  },
  {
    id: TOTAL_TYPE.increase.id,
    color: '#CCEBBD',
    type: 'area'
  },
  {
    id: TOTAL_TYPE.decrease.id,
    color: 'transparent',
    showInLegend: false,
    type: 'area'
  },
  {
    id: TOTAL_TYPE.transparent.id,
    legend: false,
    color: 'transparent',
    showInLegend: false,
    type: 'area'
  }
];
export const SINGLE_TYPE = {
  cmEffect: {
    label: '購入増加額',
    id: 'cmEffect',
    color: '#99D77B',
    type: 'column'
  },
  cmReachPurchaseAmount: {
    label: 'CMリーチ者',
    id: 'cmReachPurchaseAmount',
    color: '#7E9DE3',
    type: 'line'
  },
  cmReachPurchaseAmountWithoutCm: {
    label: 'CMリーチ者 未接触時',
    id: 'cmReachPurchaseAmountWithoutCm',
    color: '#7E9DE3',
    type: 'line',
    dashStyle: 'ShortDash'
  },
  cmUnreachedPurchaseAmount: {
    label: 'CM未接触者',
    id: 'cmUnreachedPurchaseAmount',
    color: '#A0A8A8',
    type: 'line'
  }
};

export const TOTAL_TARGET_TYPE: { label: string; value: string }[] = [
  TOTAL_TYPE.reachNumberOfPeople,
  TOTAL_TYPE.reachRate,
  TOTAL_TYPE.grp
].map(v => ({
  label: v.label,
  value: v.id
}));

export const ADD = [
  TOTAL_TYPE.totalPurchaseAmount,
  TOTAL_TYPE.totalPurchaseAmountWithoutCm,
  TOTAL_TYPE.increase,
  TOTAL_TYPE.decrease,
  TOTAL_TYPE.transparent
].map(v => ({
  label: v.label,
  value: v.id
}));

export const TABLIST: { id: string; name: string }[] = [
  {
    id: 'weeklyPurchaseIncreaseGraph',
    name: '購入増加額 推移'
  },
  {
    id: 'weeklyPurchaseIncreasePerPersonGraph',
    name: '1人当たり購入増加額 推移'
  }
];

export const TOTAL_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  series: {
    marker: {
      symbol: 'circle',
      fillColor: '#fff',
      lineWidth: 1
    }
  },
  plotOptions: {
    ...CHART_OPTIONS_BASE.plotOptions,
    area: {
      stacking: true,
      lineWidth: 0,
      shadow: false,
      marker: {
        enabled: false
      }
    },
    line: {
      zIndex: 5
    }
  }
};

export const SINGLE_CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  series: {
    marker: false
  }
};

interface ProductWeeklyReturnType {
  activeTabId: Ref<string>;
  changeTab: (v: string) => void;
  activeRadio: Ref<string>;
}

export interface Stories {
  [key: string]: (string | number)[][];
}

export const useProductWeekly = (): ProductWeeklyReturnType => {
  const { activeTabId, changeTab } = useProductWeeklyKpiTabs({
    tabList: TABLIST
  });
  const { active: activeRadio } = useProductWeeklyTotalRadios({
    radios: TOTAL_TARGET_TYPE
  });

  return {
    activeTabId,
    changeTab,
    activeRadio
  };
};

const useProductWeeklyKpiTabs = ({
  tabList
}: {
  tabList: { id: string; name: string }[];
}) => {
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const changeTab = v => {
    _changeTab(v);
  };

  return {
    activeTabId,
    changeTab
  };
};

const useProductWeeklyTotalRadios = ({
  radios
}: {
  radios: { value: string; label: string }[];
}) => {
  const active = ref(radios[0].value);

  return {
    active
  };
};

export const formatGraphData = (
  raw: CmSalesImpactGraphData
): { total_stories: Stories; single_stories: Stories } => {
  if (!raw['weeklyPurchaseIncreaseGraph'])
    return {} as { total_stories: Stories; single_stories: Stories };
  // weeklyPurchaseIncreaseGraph 整形
  const TOTAL_TARGETS = [...TOTAL_TARGET_TYPE].concat(ADD);
  const total_stories: Stories = TOTAL_TARGETS.map(j => ({
    id: j.value,
    raw: raw['weeklyPurchaseIncreaseGraph'].map(e => [e.weeklyDate, e[j.value]])
  })).reduce((obj, data) => ({ ...obj, [data.id]: data.raw }), {});
  // increase 増加
  const increase = raw['weeklyPurchaseIncreaseGraph'].map(e => [
    e.weeklyDate,
    e.totalPurchaseAmount - e.totalPurchaseAmountWithoutCm > 0
      ? e.totalPurchaseAmount - e.totalPurchaseAmountWithoutCm
      : 0
  ]);
  total_stories.increase = increase;
  // decrease 減少
  const decrease = raw['weeklyPurchaseIncreaseGraph'].map(e => [
    e.weeklyDate,
    e.totalPurchaseAmount - e.totalPurchaseAmountWithoutCm < 0
      ? Math.abs(e.totalPurchaseAmount - e.totalPurchaseAmountWithoutCm)
      : 0
  ]);
  total_stories.decrease = decrease;
  // transparent
  const transparent = raw['weeklyPurchaseIncreaseGraph'].map(e => [
    e.weeklyDate,
    Math.min(e.totalPurchaseAmount, e.totalPurchaseAmountWithoutCm)
  ]);
  total_stories.transparent = transparent;
  // cmEffect
  total_stories.cmEffect = raw['weeklyPurchaseIncreaseGraph'].map(e => [
    e.weeklyDate,
    e.cmEffect
  ]);

  // weeklyPurchaseIncreasePerPersonGraph 整形
  const single_stories: Stories = Object.values(SINGLE_TYPE)
    .map(j => ({
      id: j.id,
      raw: raw['weeklyPurchaseIncreasePerPersonGraph'].map(e => [
        e.weeklyDate,
        e[j.id]
      ])
    }))
    .reduce((obj, data) => ({ ...obj, [data.id]: data.raw }), {});

  return {
    total_stories,
    single_stories
  };
};
