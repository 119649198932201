import { ref, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CAMPAIGN_ROUTES } from '@/router';

import { CmsiApi } from '@/api';
import {
  CmSalesImpactProducts,
  CmSalesImpactProductsDetails
} from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

interface ProductList {
  id: number;
  label: string;
}
interface ProductHeader {
  isLoading: Ref<boolean>;
  products: Ref<ProductList[]>;
  selected: Ref<ProductList>;
  move: (number) => void;
}
export const useProductHeader = ({
  product
}: {
  product: CmSalesImpactProductsDetails;
}): ProductHeader => {
  const route = useRoute();
  const router = useRouter();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const products = ref([] as ProductList[]);
  const selected = ref({
    id: product.productId,
    label: `${product.productName}`
  } as ProductList);
  // 商品一覧の取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    const raw = await fetch({ companyId });
    //整形
    products.value = raw.map(v => ({
      id: v.productId,
      $isDisabled: !v.isAvailableWeeklyReport,
      icon: !v.isAvailableWeeklyReport ? 'info-circle' : '',
      label: `${v.productName} - ${v.companyName}`
    }));
  })();

  // 一覧選択時の遷移
  const move = async (id: number) => {
    await router.push({
      name: CAMPAIGN_ROUTES.cmsiProduct,
      params: { productId: id, companyId: companyId.value }
    });
  };

  return {
    isLoading,
    products,
    selected,
    move
  };
};

const _fetch = async ({ companyId }): Promise<CmSalesImpactProducts[]> => {
  // 商品の取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactProducts(companyId.value);
    return data;
  } catch (e) {
    toast({
      title: '商品／ブランドの取得失敗',
      variant: 'error'
    });
    return [] as CmSalesImpactProducts[];
  }
};
