import { ref, Ref } from 'vue';

interface UseTabsReturnType {
  activeTabId: Ref<string>;
  changeTab: (id: string) => void;
}

export const useTabs = (initialActiveId: string): UseTabsReturnType => {
  const activeTabId = ref(initialActiveId);
  const changeTab = (id: string) => {
    activeTabId.value = id;
  };

  return {
    activeTabId,
    changeTab
  };
};
