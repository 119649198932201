import { Ref, inject } from 'vue';
import { useRoute, useRouter, RouteLocationNormalizedLoaded } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';

import { CmsiApi } from '@/api';
import {
  CmSalesImpactLastAccessProduct,
  WorkspaceFeatureOptionTypeEnum
} from '@/api/openapi';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { useAreaOptions } from '@/store/areaOptions';
import { useUserInfoStore } from '@/store/userInfo';

interface CmsiReturnType {
  breadcrumbs: {
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  };
  isLoading: Ref<boolean>;
  // eslint-disable-next-line
  papa: any;
}
export const CHART_OPTIONS_BASE = {
  chart: {
    height: 290,
    animation: true,
    spacingLeft: 0,
    marginTop: 28
  },
  series: {
    borderWidth: 0
  },
  yAxis: {
    gridLineColor: '#e6e6e6',
    gridLineWidth: 0.5,
    title: {
      reserveSpace: false,
      align: 'high',
      offset: 0,
      rotation: 0,
      y: -15,
      x: 1,
      style: {
        'text-anchor': 'start',
        fontSize: 10
      }
    }
  },
  xAxis: {
    title: {
      // reserveSpace: true,
      align: 'high',
      // offset: 0,
      rotation: 0,
      // y: -15,
      // x: 1,
      style: {
        'text-anchor': 'end',
        fontSize: 10
      }
    }
  },
  legend: {
    enabled: true,
    useHTML: true,
    itemStyle: {
      fontSize: 12,
      color: '#425252',
      fontWeight: 'normal',
      verticalalign: 'top'
    },

    squareSymbol: false,
    symbolHeight: 6,
    symbolWidth: 32,
    symbolRadius: 0
  },
  subtitle: {
    align: 'right',
    y: 5,
    style: {
      // 'text-anchor': 'start',
      fontSize: 10
    }
  },
  plotOptions: {
    series: {
      pointWidth: 20
    }
  },
  title: false,
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    buttons: false,
    allowHTML: true,
    chartOptions: {
      chart: {
        marginTop: 50,
        spacingLeft: 15
      },
      title: {
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: true,
        text: '© TVAL Switch Media, Inc.'
      },
      xAxis: {
        max: null
      }
    }
  }
};

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' }
  ],
  current: { label: '売上効果分析' }
};

export const useCmsi = (): CmsiReturnType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const papa: any = inject('$papa');

  const route = useRoute();
  const { params, query } = route;
  const companyId = Number(params.companyId);

  const { isLoading } = useCmsiLastActiveProduct({
    companyId,
    query
  });

  return {
    breadcrumbs: BREADCRUMBS,
    isLoading,
    papa
  };
};

const useCmsiLastActiveProduct = ({ companyId, query }) => {
  const router = useRouter();

  const [isLoading, fetchLastActiveProduct] = useLoading(
    _fetchLastActiveProduct
  );
  (async () => {
    const data = await fetchLastActiveProduct({ companyId });
    // 最終アクセスがあり、static false
    if (query.mode !== 'static' && data.productId) {
      isLoading.value = true; // 一覧の非表示を続ける
      await router.push({
        name: CAMPAIGN_ROUTES.cmsiProduct,
        params: { productId: data.productId }
      });
    }
    // 最終アクセスがあり、static true
    if (query.mode === 'static' && data.productId) {
      await deleteLastActiveProduct({ companyId });
    }
  })();

  return {
    isLoading
  };
};

const _fetchLastActiveProduct = async ({
  companyId
}): Promise<CmSalesImpactLastAccessProduct> => {
  try {
    const { data } = await CmsiApi.getCmSalesImpactLastAccessProduct(companyId);
    return data;
  } catch (e) {
    toast({
      title: '最終アクセス取得に失敗しました',
      variant: 'error'
    });
    return {} as CmSalesImpactLastAccessProduct;
  }
};

const deleteLastActiveProduct = async ({ companyId }) => {
  try {
    await CmsiApi.deleteCmSalesImpactLastAccessProduct(companyId);
  } catch (e) {
    toast({
      title: '最終アクセス削除に失敗しました',
      variant: 'error'
    });
  }
};

export const checkAuthAccess = async (
  route: RouteLocationNormalizedLoaded
): Promise<boolean> => {
  const userInfoStore = useUserInfoStore();
  if (userInfoStore.joinableCompanies.length === 0) {
    await userInfoStore.fetchJoinableCompanies();
  }
  const notAccessMenu = type => {
    const status = userInfoStore.getFeatureOptionStatus(route, type);
    return !status;
  };
  const workspace = userInfoStore.currentWorkspaceFromRoute(route);
  const areaInfoStore = useAreaOptions();
  if (areaInfoStore.selectOptions.length === 0) {
    await areaInfoStore.fetch(route, undefined, undefined, false);
  }
  const CmSalesImpactOption = workspace?.featureOptions.find(
    v => v.type === WorkspaceFeatureOptionTypeEnum.CmSalesImpact
  );
  const status =
    // ワークスペースのTROオプションが契約中であること
    !notAccessMenu(WorkspaceFeatureOptionTypeEnum.CmSalesImpact) &&
    // カンパニーのCmSalesImpactオプションが契約中であること
    CmSalesImpactOption?.companyIds?.includes(
      Number(route.params.companyId as string)
    );

  return process.env.NODE_ENV !== 'local' && !status ? false : true;
};
