import { Ref } from 'vue';
import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

import { CmsiApi } from '@/api';
import { CmSalesImpactReports } from '@/api/openapi';

interface ReportDelete {
  isLoading: Ref<boolean>;
  onDelete: () => Promise<void>;
}

export const useReportDelete = ({
  report
}: {
  report: CmSalesImpactReports;
}): ReportDelete => {
  const { reportId } = report;
  const [isLoading, remove] = useLoading(_remove);

  const onDelete = async () => {
    await remove({ reportId });
  };
  return {
    isLoading,
    onDelete
  };
};

const _remove = async ({ reportId }) => {
  try {
    await CmsiApi.deleteCmSalesImpactReportsReportId(reportId);
    toast({
      title: '削除しました',
      variant: 'success'
    });
  } catch (e) {
    if (e.status === 409) {
      toast({
        title: 'レポート削除出来ません',
        message:
          '集計中のため、レポートの編集・削除ができません。しばらくしてから再度実行してください。',
        variant: 'error'
      });
    } else {
      toast({
        title: 'レポート削除失敗',
        message: e.message,
        variant: 'error'
      });
    }
  }
};
