import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { CmsiApi } from '@/api';
import { CmSalesImpactWeeklyKpiSummary } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

export const KPIS: { label: string; id: string; unit: string }[] = [
  { label: '購入総額', id: 'purchaseTotalAmount', unit: '円' },
  { label: '購入増加額', id: 'purchaseIncreaseAmount', unit: '円' },
  { label: 'GRP', id: 'grp', unit: '' }
];

interface CmsiWeeklySummaryReturnType {
  isLoading: Ref<boolean>;
  data: Ref<CmSalesImpactWeeklyKpiSummary>;
}

export const useCmsiWeeklySummary = ({
  productId
}: {
  productId: number;
}): CmsiWeeklySummaryReturnType => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);
  const data = ref({} as CmSalesImpactWeeklyKpiSummary);

  // 最新のレポート詳細を取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    data.value = await fetch({ companyId, productId });
  })();

  return {
    isLoading,
    data
  };
};

const _fetch = async ({
  companyId,
  productId
}): Promise<CmSalesImpactWeeklyKpiSummary> => {
  // 最新の週次KPIを取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactWeeklyKpiSummary(
      productId,
      companyId
    );
    return data;
  } catch (e) {
    toast({
      title: '週次KPIを取得失敗',
      variant: 'error'
    });
    return {} as CmSalesImpactWeeklyKpiSummary;
  }
};
