import { ref, Ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { COMPANY_ROUTES, CAMPAIGN_ROUTES } from '@/router';

import { CmsiApi } from '@/api';
import {
  CmSalesImpactReportsDetails,
  CmSalesImpactGraphData
} from '@/api/openapi';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'CMセールスインパクト' },
    {
      name: CAMPAIGN_ROUTES.cmsi,
      label: '売上効果分析',
      query: {
        mode: 'static'
      }
    },
    { name: CAMPAIGN_ROUTES.cmsiProduct, label: '', param: { productId: 0 } }
  ],
  current: { label: '' }
};
interface CmsiReportReturnType {
  breadcrumbs: Ref<{
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  }>;
  isLoading: Ref<boolean>;
  report: Ref<CmSalesImpactReportsDetails>;
  isLoadingGraph: Ref<boolean>;
  graphs: Ref<CmSalesImpactGraphData>;
  // eslint-disable-next-line
  papa: any;
}

export const getAccountImage = (): { AccountImg: string } => {
  const AccountImg = '';
  return {
    AccountImg
  };
};

export const useCmsiReport = ({
  reportId,
  isDetail
}: {
  reportId: number;
  isDetail: boolean;
}): CmsiReportReturnType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const papa: any = inject('$papa');

  const route = useRoute();
  const router = useRouter();
  const { params } = route;
  const companyId = Number(params.companyId);
  const productId = Number(params.productId);
  const report = ref({} as CmSalesImpactReportsDetails);
  const graphs = ref({} as CmSalesImpactGraphData);
  const breadcrumbs = ref(BREADCRUMBS);

  const get = async (id: number) => {
    // レポート詳細を取得
    report.value = await fetch({
      companyId,
      reportId: id
    });
    // データ集計中の場合、ページ遷移する
    if (!report.value.isAvailableWeeklyReport) {
      await router.push({
        name: CAMPAIGN_ROUTES.cmsi,
        query: {
          mode: 'static'
        }
      });
      toast({
        title: 'データ集計中',
        message: '集計中のためアクセスできません。一覧へ遷移しました。',
        variant: 'error'
      });
    }
    // レポートグラフを取得
    graphs.value = await fetchGraphs({ reportId: id, companyId, productId });
  };
  const [isLoading, fetch] = useLoading(_fetch);
  const [isLoadingGraph, fetchGraphs] = useLoading(_fetchGraphs);
  (async () => {
    await get(reportId);
    // タイトルの書き換え
    const cmsiDisplayProductName = report.value.cmsiDisplayProductName;
    const reportTitle = report.value.title;

    if (isDetail) {
      // 商品詳細でもReport/index.tsを呼び出すので、cmsiReport限定
      // product/index.tsでもtitleの書き換えを行っており、タイミングによって変動するためdocument.titleは参照しない
      document.title =
        reportTitle +
        ' | ' +
        cmsiDisplayProductName +
        ' ' +
        ' | 売上効果分析 | TVAL';
    }
    // パンくずの書き換え
    breadcrumbs.value.parents.slice(-1)[0].label = cmsiDisplayProductName;
    (breadcrumbs.value.parents.slice(-1)[0].param as {
      productId: number;
    }).productId = productId;
    breadcrumbs.value.current.label = reportTitle;
  })();

  return {
    breadcrumbs,
    isLoading,
    report,
    isLoadingGraph,
    graphs,
    papa
  };
};

const _fetch = async ({
  reportId,
  companyId
}: {
  companyId: number;
  reportId: number;
}): Promise<CmSalesImpactReportsDetails> => {
  // レポートを取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactReportsReportIdDetails(
      reportId,
      companyId
    );
    return data;
  } catch (e) {
    toast({
      title: 'レポート詳細を取得失敗',
      variant: 'error'
    });
    return {} as CmSalesImpactReportsDetails;
  }
};

export const _fetchGraphs = async ({
  reportId,
  companyId,
  productId,
  reachWeeks,
  reachFrequency,
  areaCode
}: {
  reportId?: number | undefined;
  companyId: number;
  productId: number;
  reachWeeks?: number | undefined;
  reachFrequency?: number | undefined;
  areaCode?: string | undefined;
}): Promise<CmSalesImpactGraphData> => {
  // 最新のレポートを取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactGraphData(
      productId,
      companyId,
      reportId,
      undefined,
      undefined,
      reachWeeks,
      reachFrequency,
      areaCode
    );
    return data;
  } catch (e) {
    toast({
      title: 'レポートグラフデータの取得失敗',
      variant: 'error'
    });
    return {} as CmSalesImpactGraphData;
  }
};
