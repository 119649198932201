import { ref, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import useLoading from '@/composables/loading';

import { toast } from '@/components/ui/Toast';
import { CmsiApi } from '@/api';
import {
  CmSalesImpactProducts,
  CmSalesImpactProductsProductTypeEnum
} from '@/api/openapi';

interface CmsiListReturnType {
  productTypes: Array<{ label: string; id: string }>;
  productTypeValue: Ref<CmSalesImpactProductsProductTypeEnum[]>;
  headers: Array<{ id: string; label: string; width: string }>;
  isLoading: Ref<boolean>;
  products: Ref<Array<CmSalesImpactProducts>>;
}

const HEADERS = [
  { id: 'isIcon', label: '', width: '12px' },
  { id: 'productName', label: '商品／ブランド', width: 'auto' },
  { id: 'companyName', label: '企業', width: '130px' },
  {
    id: 'purchaseIncreaseAmount',
    label: '購入増加額（前週比）',
    width: '180px'
  },
  { id: 'grp', label: '直近 GRP', width: '80px', textAlign: 'center' },
  { id: 'areaName', label: '対象エリア', width: '146px', textAlign: 'center' },
  { id: 'actions', label: '', width: '130px' }
];
const PRODUCT_TYPES = [
  { label: '自社', id: CmSalesImpactProductsProductTypeEnum.OwnCompany },
  {
    label: '競合他社',
    id: CmSalesImpactProductsProductTypeEnum.CompetitiveCompany
  }
];

export const useCmsiList = (): CmsiListReturnType => {
  const route = useRoute();
  const { params } = route;
  const companyId = Number(params.companyId);

  const productTypeValue = ref([
    CmSalesImpactProductsProductTypeEnum.OwnCompany,
    CmSalesImpactProductsProductTypeEnum.CompetitiveCompany
  ]);
  const products = ref([] as CmSalesImpactProducts[]);
  const origProducts = ref([] as CmSalesImpactProducts[]); //filter用

  // 一覧の取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    products.value = await fetch({ companyId });
    origProducts.value = [...products.value];
  })();

  // CheckBoxfilter連動
  watch(productTypeValue, value => {
    products.value = filter({
      products: origProducts.value,
      productType: value
    });
  });

  return {
    productTypes: PRODUCT_TYPES,
    productTypeValue,
    headers: HEADERS,
    isLoading,
    products
  };
};

const _fetch = async ({ companyId }): Promise<CmSalesImpactProducts[]> => {
  // 商品一覧の取得
  try {
    const { data } = await CmsiApi.getCmSalesImpactProducts(companyId);
    return data;
  } catch (e) {
    toast({
      title: '失敗',
      variant: 'error'
    });
    return [] as CmSalesImpactProducts[];
  }
};

const filter = ({ products, productType }) => {
  return products.filter(product => productType.includes(product.productType));
};

export const fluctuate = (value: number): string => {
  switch (Math.sign(value)) {
    case -1:
      return 'down';
    case 1:
      return 'up';
    default:
      return '';
  }
};
